a {
    text-decoration: none;
}

span {
    line-height: 9px;
    vertical-align: 50%;
}

.window {
    position: absolute;
    resize: both;
    overflow: auto;
    background: #fff;
    width: auto;
    min-height: auto;
    border: 1px solid #acacac;
    border-radius: 6px;
    box-shadow: 0px 0px 20px #acacac;
    z-index: 10;
    top: 5%;
    left: 5%;
}

.titlebar {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0.0, #ebebeb, color-stop(1.0, #d5d5d5)));
    background: -webkit-linear-gradient(top, #ebebeb, #d5d5d5);
    background: -moz-linear-gradient(top, #ebebeb, #d5d5d5);
    background: -ms-linear-gradient(top, #ebebeb, #d5d5d5);
    background: -o-linear-gradient(top, #ebebeb, #d5d5d5);
    background: linear-gradient(top, #ebebeb, #d5d5d5);
    color: #4d494d;
    font-size: 11pt;
    line-height: 20px;
    text-align: center;
    width: 100%;
    height: 20px;
    border-top: 1px solid #f3f1f3;
    border-bottom: 1px solid #b1aeb1;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    cursor: default;
}

.buttons {
    padding-left: 8px;
    padding-top: 3px;
    float: left;
    line-height: 0px;
}

.buttons:hover a {
    visibility: visible;
}

.close {
    background: #ff5c5c;
    font-size: 9px;
    width: 12px;
    height: 12px;
    border: 1px solid #e33e41;
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.close:active {
    background: #c14645;
    border: 1px solid #b03537;
}

.close:active .closebutton {
    color: #4e0002;
}

.closebutton {
    color: #820005;
    visibility: visible;
    cursor: pointer;
    text-align: center;
}

.content {
    padding: 20px;
    overflow: scroll;
}

.selected {
    z-index: 20;
}

@media only screen and (max-width: 750px) {
    .window {
      max-width: 500px;
      max-height: 700px;
    }
}
@media only screen and (max-width: 600px) {
    .window {
      max-width: 350px;
      max-height: 530px;
    }
}