.cuttle .overlay {
    background-color: rgba(255, 255, 255, 0.894);   
}

.cuthbert .overlay {
    background-color: #21002F;
    color: #ffffff;
}

.wave .overlay {
    background-color: #d64704;
    color: #ffffff;
}
.mb-6 {
    margin-bottom: 5rem;
}