@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.background.cuttle {
	height: 90vh;
	background: url('../../images/cuttlefish-image.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 35%;
}

@media only screen and (max-width: 1700px) {
	.background.cuttle {
		background-size: 50%;
	}
}

@media only screen and (max-width: 900px) {
	.background.cuttle {
		background-size: 90%;
	}
}

@media only screen and (max-width: 500px) {
	.background.cuttle {
		background-size: 95%;
	}
}

#root.cuttle {
	background: linear-gradient(-45deg, #f7c547, #e73c50, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	font-family: 'Lato' sans-serif;
}

#root.cuthbert {
	background-color: #21002F;
	font-family: 'Arial Black';
	color: #21002F;
	position: relative;
}

.background.cuthbert {
	height: 90vh;
	background-color: #21002F;
	background: url('../../images/cuthbert.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 85%;
}

#root.wave {
	background: url("https://media.giphy.com/media/xWMPYx55WNhX136T0V/giphy.gif");
	font-family: 'Common Pixel', sans-serif;                                    
	color: #000000;
	background-repeat: no-repeat;
	background-size: cover;
}

.background.wave {
	height: 90vh;
}
