.giphyImg {
    width: 100%;
}
@media only screen and (max-width: 750px) {
    .giphyImg {
      max-width: 450px;
    }
}
@media only screen and (max-width: 600px) {
    .giphyImg {
      max-width: 250px;
    }
}