.clockContainer {
    text-align: center;
}

.cuttle p.clock {
    color: rgb(0, 0, 0);
    min-height: 40px;
}

.cuthbert p.clock {
    color: rgb(255, 255, 255);
    min-height: 40px;
}

.wave p.clock {
    color: rgb(255, 255, 255);
    min-height: 40px;
}