#root {
  height: 100vh;
}

.pointer { 
  cursor: pointer;
}

.notAllowed {
  cursor: not-allowed;
}