.cuthBtn {
    background-color: #21002f;
    color: #ffffff;
}

.cuttleBtn {
    background-color: #23a8d3;
    color: #ffffff;
}

.waveBtn {
    background-color: #d64704;
    color: #ffffff;
}